@import "functions";
@import "variables";
@import "custom";
@import "mixins";


body {
	background: $white;
	font-family: $font-family-sans-serif;
	font-weight: 300;
	font-size: 15px;
	line-height: 1.8;
	color: gray('600');
}
a {
	transition: .3s all ease;
	text-decoration: none;
	&:hover {
		text-decoration: none;
	}
}
h1, h2, h3, h4, h5 {
	color: $black;
	font-family: $font-family-serif;
}

// Header
header {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 5;

	.navbar-brand {
		text-transform: uppercase;
		letter-spacing: .2em;
		font-weight: 400;
		color: $white!important;
	}

	

	.navbar {
		padding-top: 0;
		padding-bottom: 0;
		background: transparent!important;
		@include media-breakpoint-down(md) {
			background: rgba(0,0,0,.4)!important;
			padding-top: .5rem;
			padding-bottom: .5rem;
		}

		.nav-link {
			padding: 1.7rem 1rem;
			outline: none!important;
			@include media-breakpoint-down(md) {
				padding: .5rem .5rem;
			}
			&:hover {
				color: $white!important;
			}
			&.active {
				color: $white!important;
			}
			
		}
	
		.cta {
			// margin-top: 10px;
			float: right;
			> a {
				margin-top: -12px;
				position: relative;

				@include media-breakpoint-down(sm) {
					margin-top: inherit;
				}
				span {
					display: inline-block;
					padding: 10px 20px;
					border: 2px solid #ccc;
				}
			}
		}

		.dropdown {
			&.show {
				> a {
					color: $white!important;
				}
			}
		}

		.dropdown-menu {
			font-size: 14px;
			border-radius: 4px;
			border: none;
			box-shadow: 0 2px 30px 0px rgba(0,0,0,.2);
			min-width: 13em;
			margin-top: -10px;
			&:before {
				bottom: 100%;
				left: 10%;
				border: solid transparent;
				content: " ";
				height: 0;
				width: 0;
				position: absolute;
				pointer-events: none;				
				border-bottom-color: #fff;
				border-width: 10px;
				@include media-breakpoint-down(lg) {
					display: none;
				}
			}

			.dropdown-item {
				&:hover {
					background: theme-color('primary');
					color: $white;	
				}
				&.active {
					background: theme-color('primary');
					color: $white;
				}
			}
			a {
				padding-top: 7px;
				padding-bottom: 7px;

			}
		}
		&.navbar-light {
			.nav-link {
				color: $white;
				&.active {
					color: $white;
				}
			}
		}
	}
}
//
.site-hero {
	background-size: cover;
	background-position: center center;
	min-height: 750px;
	height: 100vh;
	
	> .container {
		position: relative;
		z-index: 2;
	}
	&.overlay {
		position: relative;
		&:before {
			content: "";
			background: rgba(0,0,0,.5);
			width: 100%;
			z-index: 1;
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
		}
		h1, p {
			color: $white;
		}
		h1 {
			font-size: 60px;
			@include media-breakpoint-down(lg) {
				font-size: 40px;
			}
		}
		p {
			font-size: 30px;
			@include media-breakpoint-down(lg) {
				font-size: 20px;
			}
		}
	}
	.site-hero-inner {
		min-height: 750px;
		height: 100vh;
	}
}
// room

.room {
	background: $white;
	margin-bottom: 30px;
	
	figure {
		position: relative;
		float: left;
		overflow: hidden;
		&:after {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: $black;
			opacity: .5;
			content: "";
		}
		.overlap-text {
			z-index: 99;
			position: absolute;
			bottom: 20px;
			left: 20px;
			color: $white;
			text-transform: uppercase;
			font-size: 13px;
		}
	}
	.media-body {
		padding: 30px;
		border: 1px solid lighten($black, 90%);
		border-top: none;
		.room-specs {
			margin: 0;
			padding: 0;
			li {
				display: inline-block;
				list-style: none;
				margin: 0;
				padding: 0 20px 20px 0;
				span {
					font-size: 30px;
					position: relative;
					top: 4px;
					margin-right: 10px;
				}
			}
		}
	}	
}
.room-thumbnail-absolute {
	position: relative;
	.room {
		position: relative;

		&.bg {
			position: relative;
			height: 47.8%;
			width: 100%;
			background-size: cover;
			&:last-child {
				margin-bottom: 0;
			}
		}
		.pricing-from {
			margin-left: 10px;
			padding: 5px 10px;
			background: $yellow;
			border-radius: 4px;
			font-size: 13px;
		}

		&:after {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: $black;
			opacity: .5;
			content: "";
		}
		.overlap-text {
			bottom: 20px;
			left: 20px;
			width: 100%;
			position: absolute;
			z-index: 10;
			span {
				color: $white;
				text-transform: uppercase;
				font-size: 13px;
			}
		}
	}
}

.site-hero-innerpage {

	&, .site-hero-inner {
		min-height: 550px;
		height: 50vh;
	}
}
.site-section {
	padding: 4em 0 2em;

	// 最後のセクションスタイル
	&.end-section {
		padding: 4em 0;

	} // &.end-section end
} // site-section end

#search-form {
	.search-input {
		width: calc(100% - 160px);
		height: 55px;
		border-radius: 0;
		padding-left: 20px;
		padding-right: 20px;
		border: none;
		&:hover, &:active, &:focus {
			border: none;
		}
	}	
	button {
		height: 55px;
		width: 160px;
		border-radius: 0;
	}
}

.img-bg {
	background-size: cover;
}
.img-md-fluid {
	@include media-breakpoint-down(sm) {
		max-width: 100%;
	}
}

// features destination
.feature-destination, .slider-wrap {
	position: relative;
	z-index: 10;
	margin-top: -50px;
	margin-bottom: 50px;
	.img-bg {
		margin-bottom: 30px;
		display: block;
		height: 300px;
		position: relative;
		top: 0;
		transition: .2s all ease;
		&:before {
			content: "";
			top: 0; 
			left: 0;
			bottom: 0;
			right: 0;
			background: $black;
			opacity: .1;
			position: absolute;
			transition: .3s all ease;
		}

		.text {
			position: absolute;
			top: 50%;
			left: 50%;
			text-align: center;
			width: 80%;
			transform: translate(-50%, -50%);
			.icon {
				color: $white;
				font-size: 30px;
				position: relative;
				transition: .3s all ease;
				top: 0;
			}
			h2 {
				transition: .3s all ease;
				margin: 0;
				padding: 0;
				font-size: 18px;
				font-weight: 400;
				color: $white;
				top: 0px;
				position: relative;
			}
			p {
				color: $white;
				opacity: 0;
				top: 10px;
				visibility: hidden;
				font-size: 13px;
				position: relative;
				transition: .3s all ease;
			}
		}

		&:hover, &:focus {
			top: -10px;
			box-shadow: 2px 0 20px 0 rgba(0,0,0,.4);
			.text {
				.icon {
					top: -10px;
				}
				h2 {
					top: -10px;
				}
				p {
					top: 0;
					opacity: .5;
					visibility: visible;
				}
			}
			&:before {
				opacity: .4;
			}
		}
		

	}
}

.slider-wrap {
	margin-top: 0;
	.img-bg {
		&:hover {
			top: 0;
		}
	}
}

.btn-play-wrap {
	.btn-play {
		width: 70px;
		height: 70px;
		line-height: 50px;
		border: 2px solid $white;
		border-radius: 50%;
		display: inline-block;
		text-align: center;
		position: relative;
		span {
			position: absolute;
			top: 50%;
			left: 50%;
			font-size: 40px;
			transform: translate(-30%, -50%);
			color: $white;
		}
	}
}
.section-cover {
	background-size: cover;
	// background-repeat: no-repeat;
	position: relative;
	background-position: top left;
	
	&, .intro {
		height: 500px;	
	}
	p {
		color: $white;
	}
	h2 {
		color: $white;
		font-size: 50px;
	}
}
.top-destination {
	.place {
		display: block;
		transition: .3s all ease;
		&:hover, &:focus {
			opacity: .7;
		}
		img {
			max-width: 100%;
			margin-bottom: 20px;
		}
		h2 {
			font-size: 18px;
			line-height: 1.5;
		}
		p {
			font-size: 13px;
			color: #ccc;
		}
	}
}

.btn, .form-control {
	outline: none;
	box-shadow: none!important;
	border-radius: 0;
	&:focus, &:active {
		outline: none;
	}
}

.form-control {
	box-shadow: none!important;
}
textarea.form-control {
	height: inherit;
}
.btn {
	transition: .3s all ease;
	padding: 12px 30px;
	text-transform: uppercase;
	letter-spacing: .15em;
	&.btn-black {
		background: $black;
		color: $white;
		
	}
	&.btn-primary {
		color: $white;
		border-width: 2px;
		&:hover, &:active, &:focus {
			border-color: lighten(theme-color('primary'), 10%); 
			background: lighten(theme-color('primary'), 10%);
		}
	}
	&.btn-sm {
		font-size: 12px;
	}
	&.btn-outline-primary {
		border-width: 2px;
		color: theme-color('primary');
		&:hover, &:focus, &:active {
			color: $white;
		}
	}
	&.btn-outline-white {
		border-width: 2px;
		border-color: $white;
		color: $white;
		&:hover, &:focus {
			background: $white;
			color: $black;
			border-width: 2px;
		}
	}
	&:hover {
		box-shadow: 0 3px 10px -2px rgba(0,0,0,.2)!important;
	}
}

// Flaticon 
.custom-icon {
	font-size: 70px;
	color: theme-color('primary');
}


// Owl Carousel 
.no-nav {
	.owl-nav {
		display: none;
	}
}
.owl-carousel {


	.owl-item {
		opacity: .4;
		&.active {
			opacity: 1;
		}
	}
	
	.owl-nav {
		position: absolute;
		top: 50%;
		width: 100%;
		.owl-prev,
		.owl-next {
			position: absolute;
			transform: translateY(-50%);
			margin-top: -10px;
			&:hover, &:focus, &:active {
				outline: none;
			}
			span {
				&:before {
					font-size: 40px;
				}
			}
		}
		.owl-prev {
			
			left: 30px!important;
			// border: 1px solid red;
		}
		.owl-next {
			right: 30px!important;
		}
	}
	.owl-dots {
		text-align: center;
		.owl-dot {
			width: 10px;
			height: 10px;
			margin: 5px;
			border-radius: 50%;
			background: lighten($black, 90%);
			&.active {
				background: theme-color('primary');
			}
		}
	}

	// big slider
	&.home-slider {
		z-index: 1;
		position: relative;
		.owl-nav {
			opacity: 0;
			visibility: hidden;
			transition: .3s all ease;
			button {
				color: $white;
			}
		}
		&:focus, &:hover {
			.owl-nav {
				opacity: 1;
				visibility: visible;
			}
		}

		.slider-item {
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			height: calc(100vh - 117px);
			min-height: 700px;	
			position: relative;
			&:before {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: rgba(0,0,0,.2);
				content: "";
			}
			// .slider-overlay {
			// 	position: absolute;
			// }
			.slider-text {
				color: $white;
				height: calc(100vh - 117px);
				min-height: 700px;
				.child-name {
					font-size: 40px;
					color: $white;
				}
				h1 {
					font-size: 40px;
					color: $white;
					line-height: 1.2;
					font-weight: 800!important;
					text-transform: uppercase;
					@include media-breakpoint-down(md) {
						font-size: 40px;
					}
				}
				p {
					font-size: 20px;
					line-height: 1.5;
					font-weight: 300;
					color: rgba(255,255,255,1);
				}
			}
			&.dark {
				.child-name {
					color: $black;
				}
				h1 {
					color: $black;
				}
				p {
					color: $black;
				}
			}
		}
		&.inner-page {
			.slider-item {
				height: calc(50vh - 117px);
				min-height: 350px;	
				.slider-text {
					color: $white;
					height: calc(50vh - 117px);
					min-height: 350px;
				}
			}
		}
		.owl-dots {
			position: absolute;
			bottom: 100px;
			width: 100%;

			.owl-dot {
				width: 10px;
				height: 10px;
				margin: 5px;
				border-radius: 50%;
				border: 2px solid rgba(255,255,255,.5);
				outline: none!important;
				position: relative;
				transition: .3s all ease;
				&.active {
					border: 2px solid rgba(255,255,255,1);
					span {	
						background: rgba(255,255,255,1);
					}	
				}
			}

		}
	}
	&.major-caousel {
		.owl-stage-outer {
			padding-top: 30px;
			padding-bottom: 30px;
		}
		.owl-nav {
			.owl-prev, .owl-next {
				transition: .3s all ease;
				color: gray('700');
				&:hover, &:focus {
					color: gray('600');
					outline: none;
				}
				&.disabled {
					color: gray('300');
				}
			}
			.owl-prev {
				left: -60px!important;
			}
			.owl-next {
				right: -60px!important;
			}
		}
		.owl-dots {
			bottom: -30px!important;
			position: relative;
		}
	}
}
.owl-custom-nav {
	float: right;
	position: relative;
	z-index: 10;
	.owl-custom-prev,
	.owl-custom-next {
		padding: 10px;
		font-size: 30px;
		background: #ccc;
		line-height: 0;
		width: 60px;
		text-align: center;
		display: inline-block;
	}
} 


// box
.box {
	overflow: hidden;
	// border-top-left-radius: 7px;
	// border-top-right-radius: 7px;
	border-radius: 4px;
	display: block;
	img {
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		transition: .3s all ease;
	}
	.box-body {
		padding: 20px;
		border: 1px solid gray('200');
		border-top: none;
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
		transition: .3s all ease;
	}
	h2 {
		font-size: 18px;
		font-family: $font-family-sans-serif;
		transition: .3s all ease;
	}
	&.hover {
		
		
		&:hover, &:focus {
			img {
				margin-top: -20px;
			}
			.box-body {
				background: $primary;
				color: $white;
				padding: 30px 20px;
				border-color: $primary;
			}
			h2 {
				color: $white;
			}
		}
	}
}

// Breadcrumbs 
.breadcrumb-custom {
	background: none;
	padding: 0;
	li {
		a {
			color: theme-color('primary');
			&:hover {
				color: $white;
			}
		}
		&.active {
			color: $white;
		}

		
		&.breadcrumb-item+.breadcrumb-item:before {
			content: "/";
			color: rgba(255,255,255,.3);
		}
	}
}

.pagination {
	float: none;
	display: inline-block;
	li {
		display: inline-block;
	}
}
.post-entry {
	margin-bottom: 30px;
	.body-text {
		.category {
			position: relative;
			padding-bottom: 10px;
			margin-bottom: 10px;
			text-transform: uppercase;
			font-size: 12px;
			letter-spacing: .2em;
			color: #ccc;
			&:after {
				content: "";
				left: 0;
				width: 50px;
				height: 1px;
				background: $black;
				position: absolute;
				bottom: 0;
			}
		}
		padding:30px;
		background: $white;
	}
}
// Children info
.children-info {
	li {
		display: block;
		margin-bottom: 15px;
		padding-bottom: 15px;
		border-bottom: 1px dotted gray('300');
	}
}


// sidebar
.sidebar {
	padding-left: 5em;
	@include media-breakpoint-down(md) {
		padding-left: 15px;
	}
}
.sidebar-box {
	margin-bottom: 4em;
	// padding: 0 25px;
	font-size: 15px;
	width: 100%;
	
	float: left;
	
	background: $white;
	*:last-child {
		margin-bottom: 0;
	}
	.heading {
		font-size: 18px;
		margin-bottom: 30px;
		padding-bottom: 20px;
		border-bottom: 1px solid lighten($black, 90%);
	}
}

.tags {
	padding: 0;
	margin: 0;
	font-weight: 400;
	li {
		padding: 0;
		margin: 0 4px 4px 0;
		float: left;
		display: inline-block;
		a {
			float: left;
			display: block;
			border-radius: 4px;
			padding: 2px 6px;
			color: lighten($black, 50%);
			background: lighten($black, 95%);
			&:hover {
				color: $white;
				background: $primary;
			}
		}
	}
}

// sidebar
.sidebar-box {
	margin-bottom: 30px;
	padding: 25px;
	font-size: 15px;
	width: 100%;
	border-radius: 7px;
	float: left;
	ul {
		li {
			list-style: none;
		}
	}
	// border: 2px solid lighten(#ccc, 14%);
	background: $white;
	*:last-child {
		margin-bottom: 0;
	}
	h3 {
		font-size: 18px;
		margin-bottom: 15px;
	}
}

.categories, .sidelink {
	padding: 0;
	li {
		position: relative;
		margin-bottom: 10px;
		padding-bottom: 10px;
		border-bottom: 1px dotted gray('300');
		&:last-child {
			margin-bottom: 0;
			border-bottom: none;
			padding-bottom: 0;
		}
		a {
			display: block;
			span {
				position: absolute;
				right: 0;
				top: 0;
				color: #ccc;
			}
		}
		&.active {
			a {
				color: $black;
				font-style: italic;
			}
		}
	}
}

.cover_1 {
	background-size: cover;
	background-position: center center;
	padding: 7em 0;
	.sub-heading {
		color: rgba(255,255,255,.7);
		font-size: 22px;
	}
	.heading {
		font-size: 50px;
		color: rgba(255,255,255,1);
		font-weight: 300;
	}
}


.heading-wrap {
	.heading {
		font-size: 40px;
			color: #b99365;

			@media screen and (max-width: 768px) {
				padding: 0 5%;
				font-size: 30px;
			}
			@media screen and (max-width: 320px) {
				padding: 0;
				font-size: 26px;
			}

		// 改行用
		.br-sp {

			@media screen and (max-width: 768px) {
				display: block;
			}

		} // br-sp end

		// もう一つのフォントスタイル
		&-other-title {
			font-size: 40px;
			color: #b99365;

			@media screen and (max-width: 768px) {
				padding: 0 5%;
				font-size: 30px;
			}
			@media screen and (max-width: 320px) {
				padding: 0;
				font-size: 26px;
			}

		} // &-other-title end

	}
	.sub-heading {
		font-size: 12px;
		text-transform: uppercase;
		letter-spacing: .1em;
		color :#ccc;
		font-family: $font-family-sans-serif;
	}
}
// Stretch
.stretch-section {
	.video {
		display: block;
		position: relative;
		box-shadow: 4px 4px 70px -20px rgba(0,0,0,.5);
	}
}

.media-feature {
	padding:30px;
	transition: .2s all ease-out;
	background: $white;
	z-index: 1;
	position: relative;
	border-bottom: 10px solid transparent;
	border-radius: 4px;
	font-size: 15px;
	.icon {
		font-size: 60px;
		color: theme-color('primary');
	}
	h3 {
		font-size: 16px;
		text-transform: uppercase;
	}
	&:hover, &:focus {
		box-shadow: 0 2px 20px -3px rgba(0,0,0,.1);
		transform: scale(1.05);
		z-index: 2;
		border-bottom: 10px solid theme-color('primary');
	}
}

.media-custom {
	background: $white;
	transition: .3s all ease;
	margin-bottom: 30px;
	position: relative;
	top: 0;
	.meta-post {
		color: gray('400');
		font-size: 13px;
		text-transform: uppercase;
	}
	> a {
		position: relative;
		overflow: hidden;
		display: block;
	}
	.meta-chat {
		color: gray('400');
		&:hover {
			color: gray('600');
		}
	}
	img {
		transition: .3s all ease;
	}
	&:focus, &:hover {
		top: -2px;
		box-shadow: 0 2px 40px 0 rgba(0,0,0,.2);
		img {
			transform: scale(1.1);
		}
	}
	.media-body {
		padding: 30px;
		h3 {
			font-size: 20px;
		}
		p {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

#accordion {
	.card {
		font-size: 15px;
		border-color: gray('300');
		h5 {
			a {
				display: block;
				text-align: left;
				text-decoration: none;
				color: theme-color('primary');
				position: relative;
				box-shadow: 0 3px 10px -2px rgba(0,0,0,.2)!important;
				border-radius: 0;
				.icon {
					position: absolute;
					right: 20px;
					top: 50%;
					transform: translateY(-50%) rotate(-180deg);
					transition: .3s all ease;
				}
				&:hover {
					text-decoration: none;
					box-shadow: 0 3px 10px -2px rgba(0,0,0,.2)!important;
				}
				&.collapsed {
					color: $black;
					box-shadow: none!important;
					.icon {
						right: 20px;
						top: 50%;
						transform: translateY(-50%);
					}
					&:hover {
						text-decoration: none;
						box-shadow: 0 3px 10px -2px rgba(0,0,0,.2)!important;
					}
				}
			}
		}
		.card-body {
			padding-top: 15px;
		}	
	}
	
}

.media-testimonial {
	img {
		width: 100px;
		border-radius: 50%;
	}
	blockquote {
		p {
			font-size: 20px;
			color: $black;
			font-style: italic;
		}
	}
}

// list style
.list-unstyled {
	&.check {
		li {
			position: relative;
			padding-left: 30px;
			line-height: 1.3;
			margin-bottom: 10px;
			&:before {
				color: color('cyan');
				left: 0;
				font-family: "Ionicons"; 
				content: "\f122";
				position: absolute;
			}
		}
	}
}



// Modal
#modalAppointment {
	.modal-content {
		border-radius: 0;
		border: none;
	}
	.modal-body, .modal-footer {
		padding: 40px;
	}
}

// Footer
.site-footer {
	padding: 5em 0;
	background: lighten(#ccc, 15%);
	font-size: 16px;
	h3 {
		margin-bottom: 20px;
		font-family: $font-family-sans-serif;
		font-size: 13px;
		letter-spacing: .2em;
		color: lighten($black, 70%);
		text-transform: uppercase;
	}
	.list-unstyled {
		li {
			margin-bottom: 15px;
			h3 {
				font-size: 16px;
				font-weight: normal;
				font-family: $font-family-sans-serif;
			}
		}
	}
	p {
		color: $black;
		&:last-child {
			margin-bottom: 0;
		}
	}
	
	a {
		color: $black;
		&:hover {
			opacity: .4;
		}
	}

	.footer-link {
		li {
			line-height: 1.5;
			margin-bottom: 15px;
		}
	}
	hr {
		width: 100%;
	}

}

.footer {
  background-color: #EEE;
  padding-top: 30px;
}
@media screen and (max-width: 991px) {
  .footer {
    padding-top: 20px;
  }
}

@media screen and (max-width: 991px) {
  .footer__nav_cat {
    //display: none !important;
  }
}

@media screen and (max-width: 991px) {
  .footer__nav_about {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.footer .container {
	position: relative;
	padding-bottom: 30px;
}

@media screen and (max-width: 991px) {
  .footer .container {
	padding-bottom: 0;
}
}

.footer .logo-link {
	text-transform: uppercase;
	font-size: 18px;
	letter-spacing: .2em;
	font-weight: 400;
	color: #b99365;
}

.footer__nav__title {
  color: #b99365;
  font-weight: bold;
  font-size: 12px;
  padding-bottom: 15px;
}
@media screen and (max-width: 991px) {
  .footer__nav__title {
    font-size: 11px;
    padding-bottom: 20px;
  }
}

.footer__nav__list {
  *zoom: 1;
  padding-bottom: 30px;
	list-style: none;
	
	@media screen and (max-width: 991px) {
    padding-bottom: 10px;
  }
}
.footer__nav__list:after {
  content: "";
  display: table;
  clear: both;
}

.footer__nav__list__item {
  float: left;
  font-size: 12px;
  padding-right: 20px;
}
@media screen and (max-width: 991px) {
  .footer__nav__list__item {
    float: none;
    font-size: 11px;
    padding-bottom: 15px;
    padding-right: 0;
  }
}
.footer__nav__list__item:after {
  border-left: 1px dotted #bbb;
  content: "";
  height: auto;
  margin-left: 20px;
  width: 1px;
}
@media screen and (max-width: 991px) {
  .footer__nav__list__item:after {
    content: none;
  }
}
.footer__nav__list__item:last-child {
  padding-right: 0;
}
@media screen and (max-width: 991px) {
  .footer__nav__list__item:last-child {
    padding-bottom: 0;
  }
}
.footer__nav__list__item a {
  color: #444;
  text-decoration: none;
}

.footer__logo {
  position: absolute;
  top: 0;
  right: 45px;
  bottom: "";
  left: "";
}
@media screen and (max-width: 991px) {
  .footer__logo {
    margin-top: -27px;
    right: 30px;
    top: 50%;
  }
}
@media screen and (max-width: 991px) {
  .footer__logo img {
    width: 109px;
  }
}

.footer__btn {
  position: absolute;
  top: 70px;
  right: 55px;
  bottom: "";
  left: "";
	
	@media screen and (max-width: 991px) {
		position: static;
		width: 100%;
		padding-bottom: 12px;
		@include clearfix;
	}
}

.footer__btn__item {
	list-style: none;
  float: left;
  padding-right: 10px;
}
.footer__btn__item:last-child {
  padding-right: 0;
}
.footer__btn__item a {
  display: table-cell;
  background-color: #b99365;
  border-radius: 50%;
  height: 30px;
  text-align: center;
  width: 30px;
  vertical-align: middle;
}
.footer__btn__item a img {
  height: auto;
  width: 20px;
}

.footer_jed {
  background-color: #f3f3f4;
}

.footer_jed__img{
  text-align: center;
  line-height: 0;
  padding: 11px 0 12px 0;
  margin: 0;
}

.footer__copyright {
  background-color: #DDD;
}

.footer__copyright__txt {
	margin: 0 auto;
  position: relative;
  width: 1000px;
  font-size: 10px;
  line-height: 72px;
  text-align: center;
}
@media screen and (max-width: 991px) {
  .footer__copyright__txt {
    width: auto;
  }
}
@media screen and (max-width: 991px) {
  .footer__copyright__txt {
    line-height: 1;
    padding-bottom: 15px;
    padding-top: 15px;
  }
}

.subscribe {
	.form-group {
		position: relative;
	}
	input[type="email"] {
		padding-right: 40px;
	}
	button {
		border: none;
		background: none;
		cursor: pointer;
		right: 10px;
		top: 50%;
		transform: translateY(-50%);
		position: absolute;
		span {
			font-size: 30px;
			
		}
	}
}

.search-form-wrap {
	margin-bottom: 5em;
	display: block;
}
.search-form {
	.form-group {
		position: relative;
		#s {
			padding-right: 50px;
			background: lighten($black, 97%);
			padding: 15px 15px;
			border: none;

		}
	}
	.icon {
		position: absolute;
		top: 50%;
		right: 20px;
		transform: translateY(-50%);
	}
}




.post-entry-sidebar {
	.post-meta {
		font-size: 14px;
		color: lighten($black, 70%);
	}
	ul {
		padding: 0;
		margin: 0;
		li {
			list-style: none;
			padding: 0 0 20px 0;
			margin: 0 0 20px 0;
			a {
				display: table;
				img {
					width: 90px;
					height: auto;

				}
				img, .text {
					display: table-cell;
					vertical-align: middle;
				}
				.text {
					h4 {
						font-size: 18px;
					}
				}
			}

		}
	}
}


.comment-form-wrap {
	clear: both;
}

.comment-list {
	padding: 0;
	margin: 0;
	.children {
		padding: 50px 0 0 40px;
		margin: 0;
		float: left;
		width: 100%;
	}
	li {
		padding: 0;
		margin: 0 0 30px 0;
		float: left;
		width: 100%;
		clear: both;
		list-style: none;
		.vcard {
			width: 80px;
			float: left;
			img {
				width: 50px;
				border-radius: 50%;
			}
		}
		.comment-body {
			float: right;
			width: calc(100% - 80px);
			h3 {
				font-size: 20px;
			}
			.meta {
				text-transform: uppercase;
				font-size: 13px;
				letter-spacing: .1em;
				color: #ccc;
			}
			.reply {
				padding: 5px 10px;
				background: lighten($black, 90%);
				color: $black;
				text-transform: uppercase;
				font-size: 14px;
				&:hover {
					color: $black;
					background: lighten($black, 89%);
				}
			}
		}
	}
}


.blog-entry-footer {
	.post-meta {
		color: lighten($black, 50%);
		font-size: 15px;
	}
}

.border-t {
	border-top: 1px solid gray('100');
}

.copyright {
	font-size: 14px;
}

// animate
.element-animate {
	opacity: 0;
	visibility: hidden;
}

// loader

#loader {
	position: fixed;
	width:  96px;
	height: 96px;
	left:  50%;
	top: 50%;
	transform: translate(-50%, -50%);
	background-color: rgba(255,255,255,0.9);
	box-shadow: 0px 24px 64px rgba(0,0,0,0.24);
	border-radius:16px;
	opacity: 0; 
	visibility: hidden;
	transition: opacity .2s ease-out, visibility 0s linear .2s;
	z-index:1000;
}

#loader.fullscreen {
	padding:  0;
	left:  0;
	top:  0;
	width:  100%;
	height: 100%;
	transform: none;
	background-color: #fff;
	border-radius: 0;
	box-shadow: none;
}

#loader.show {
	transition: opacity .4s ease-out, visibility 0s linear 0s;
	visibility: visible;
	opacity: 1;
}

#loader .circular {
  animation: loader-rotate 2s linear infinite;
  position: absolute;
  left:  calc(50% - 24px);
  top:  calc(50% - 24px);
  display: block;
  transform: rotate(0deg);
}

#loader .path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: loader-dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes loader-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -136px;
  }
}

// GRACES JAPANのサービスページのスタイルを流用
.asset__cat__title {
  background-image: url(img/common/kazarikei.png);
  background-position: left bottom;
  background-repeat: repeat-x;
  color: #b99365;
  font-size: 16px;
  letter-spacing: 1px;
  margin-bottom: 30px;
  padding-bottom: 20px;
  text-align: center;
}
@media screen and (max-width: 991px) {
  .asset__cat__title {
    display: none !important;
  }
}
@media screen and (max-width: 991px) {
  .asset__cat__title {
    font-size: 11px;
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
}
.asset__cat__title img {
  display: block;
  margin: 0 auto;
  padding-bottom: 15px;
  width: 65px;
}
@media screen and (max-width: 991px) {
  .asset__cat__title img {
    padding-bottom: 10px;
    width: 77px;
  }
}

.asset__header {
  border-bottom: 1px dotted #BBB;
  margin-bottom: 15px;
  padding-bottom: 65px;
  position: relative;
}
@media screen and (max-width: 991px) {
  .asset__header {
    background-image: url(img/common/kazarikei.png);
    background-position: left top;
    background-repeat: repeat-x;
    border: none;
    margin-bottom: 0;
    padding-bottom: 20px;
    padding-top: 20px;
  }
}

.asset__title {
  color: #b99365;
  font-size: 32px;
  line-height: 1.6;
  padding-bottom: 20px;
}
@media screen and (max-width: 991px) {
  .asset__title {
    font-size: 19px;
    font-weight: bold;
  }
}

.asset__info {
  position: absolute;
  top: "";
  right: 0;
  bottom: 30px;
  left: 0;
  font-size: 0;
  text-align: center;
}
@media screen and (max-width: 991px) {
  .asset__info {
    position: static;
  }
}

.asset__info__item {
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
}
@media screen and (max-width: 991px) {
  .asset__info__item {
    background-color: #f1f1f1;
    border-left: 2px solid #fff;
    border-right: 2px solid #fff;
    line-height: 40px;
    margin-bottom: 4px;
    margin-left: 0;
    text-align: center;
    width: 50%;
  }
}

.asset__info__item_date {
  color: #777;
  font-size: 14px;
}
@media screen and (max-width: 991px) {
  .asset__info__item_date {
    font-size: 11px;
  }
}

.asset__info__item_view {
  color: #525252;
  font-size: 12px;
}
@media screen and (max-width: 991px) {
  .asset__info__item_view {
    font-size: 10px;
  }
}
.asset__info__item_view span {
  font-size: 18px;
  font-weight: bold;
}
@media screen and (max-width: 991px) {
  .asset__info__item_view span {
    font-size: 15px;
  }
}

.asset__info__btn {
  font-size: 0;
  text-align: center;
}
@media screen and (max-width: 991px) {
  .asset__info__btn {
    border-spacing: 5px 0;
    display: table;
    table-layout: fixed;
    width: 100%;
  }
}

.asset__info__btn__item {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}
@media screen and (max-width: 991px) {
  .asset__info__btn__item {
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    display: table-cell;
    float: none;
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
    vertical-align: middle;
  }
}
.asset__info__btn__item:last-child {
  padding-right: 0;
}
.asset__info__btn__item a {
  display: table-cell;
  background-color: #b99365;
  border-radius: 50%;
  height: 40px;
  text-align: center;
  width: 40px;
  vertical-align: middle;
}
@media screen and (max-width: 991px) {
  .asset__info__btn__item a {
    border-radius: 0;
    display: block;
    padding-top: 6px;
    width: 100%;
  }
}
.asset__info__btn__item a img {
  height: auto;
  width: 25px;
}

.asset__tag {
  *zoom: 1;
  padding-bottom: 20px;
}
.asset__tag:after {
  content: "";
  display: table;
  clear: both;
}
@media screen and (max-width: 991px) {
  .asset__tag {
    display: none !important;
  }
}

.asset__tag__item {
  float: left;
  font-size: 12px;
  padding-bottom: 5px;
  padding-right: 5px;
}
.asset__tag__item a {
  background-color: #F3F3F3;
  background-image: url(img/common/icon-pink-tag.png);
  background-position: 5px center;
  background-repeat: no-repeat;
  background-size: 16px;
  border-radius: 2px;
  color: #333;
  display: block;
  padding: 8px 10px 8px 30px;
  text-decoration: none;
}

.asset__body h3 {
  background-color: #F3F3F3;
  color: #b99365;
  font-size: 22px;
  line-height: 1.2;
  margin-bottom: 20px;
  padding: 10px 20px;
}
@media screen and (max-width: 991px) {
  .asset__body h3 {
    font-size: 18px;
  }
}
.asset__body h4 {
  color: #b99365;
  font-size: 20px;
  line-height: 1.2;
  padding-bottom: 20px;
}
@media screen and (max-width: 991px) {
  .asset__body h4 {
    font-size: 16px;
  }
}
.asset__body p {
  font-size: 16px;
  line-height: 1.6;
  padding-bottom: 20px;
}
@media screen and (max-width: 991px) {
  .asset__body p {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.asset__body .profile {
  *zoom: 1;
  display: flex;

  @media screen and (max-width: 767px) {
	display: block;
  }
}
.asset__body .profile:after {
  content: "";
  display: table;
  clear: both;
}
.asset__body .profile p {
  //float: right;
  width: 50%;
}
@media screen and (max-width: 991px) {
  .asset__body .profile p {
    float: none;
    width: auto;
  }
}
.asset__body .profile__img {
  //float: left;
  width: 200px;
  margin-right: 50px;

  @media screen and (max-width: 767px) {
	width: 100%;
	margin-right: 0;
  }

  img {
	  width: 100%;
	  height: auto;

  } // img end

} // asset__body .profile__img end

@media screen and (max-width: 991px) {
  .asset__body .profile__img {
    float: none;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    width: auto;
  }
}
.asset__body .name {
  font-size: 22px;
}
@media screen and (max-width: 991px) {
  .asset__body .name {
    font-size: 18px;
  }
}
.asset__body .cap {
  color: #666;
  font-size: 14px;
  margin-top: -10px;
}
.asset__body img {
  display: block;
}
.asset__body .bold01 {
  font-weight: bold;
}
.asset__body .panel01 {
  background-color: #FaFaFa;
  margin-bottom: 20px;
  padding: 10px;
}
.asset__body .panel01 p {
  padding-bottom: 0;
}

@media screen and (max-width: 991px) {
  .asset__aside {
    display: none !important;
  }
}

.asset__aside__title {
  background-image: url(img/common/kazarikei.png);
  background-position: left bottom;
  background-repeat: repeat-x;
  color: #b99365;
  font-size: 16px;
  letter-spacing: 1px;
  margin-bottom: 30px;
  padding-bottom: 20px;
  text-align: center;
}
@media screen and (max-width: 991px) {
  .asset__aside__title {
    font-size: 11px;
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
}
.asset__aside__title img {
  display: block;
  margin: 0 auto;
  padding-bottom: 15px;
  width: 65px;
}
@media screen and (max-width: 991px) {
  .asset__aside__title img {
    padding-bottom: 10px;
    width: 77px;
  }
}
@media screen and (max-width: 991px) {
  .asset__aside__title {
    background-image: none;
    margin-bottom: 0;
  }
}

.asset__aside__author {
  *zoom: 1;
}
.asset__aside__author:after {
  content: "";
  display: table;
  clear: both;
}

.asset__aside__author__img {
  float: left;
  width: 100px;
}
.asset__aside__author__img img {
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  border-radius: 100px;
  width: 100px;
}

.asset__aside__author__txt {
  float: right;
  width: 550px;
}
.asset__aside__author__txt dt {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
  padding-top: 10px;
}
.asset__aside__author__txt dd {
  font-size: 12px;
  line-height: 1.4;
}

.asset__aside__cat {
  font-size: 0;
}

.asset__aside__cat__item {
  display: inline-block;
  font-family: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-size: 14px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
}
.asset__aside__cat__item:first-child {
  padding-left: 0px;
}
.asset__aside__cat__item a {
  background-color: #b99365;
  background-image: url(img/common/caret-white-right.png);
  background-repeat: no-repeat;
  background-position: 5px center;
  color: #FFF;
  display: block;
  line-height: 30px;
  position: relative;
  text-decoration: none;
  width: 130px;
}

// Clearfix
.clearfix::after {
  content: "";
  display: block;
  clear: both;
}

.form__btn, .wpcf7-submit, a img {
  border: none
}

.form {
  margin-left: auto;
  margin-right: auto;
  width: 800px;
  padding-bottom: 70px;
  padding-top: 30px
}

.form__head {
  color: #b99365;
  font-family: "メイリオ", Meiryo, "Hiragino Sans", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", sans-serif;
  font-size: 16px;
  padding-bottom: 50px
}

@media screen and (max-width:767px) {
  .form {
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
  }
  .form__head {
    font-size: 8px;
    padding-bottom: 40px
  }
}

.form__head img {
  display: block;
  margin: 0 auto;
  padding-bottom: 25px
}

@media screen and (max-width:767px) {
  .form__head img {
    padding-bottom: 10px;
    width: 80px
  }
  .form__table {
    border-top: 1px solid #aaa
  }
}

.form__txt {
  padding-bottom: 30px;
  line-height: 1.4
}

.form__table {
  margin-bottom: 30px;
  width: 100%
}

.form__table__heading {
  background-color: #F1F1F1;
  border: 1px solid #aaa;
  padding: 15px;
  width: 15em;
  vertical-align: top
}

@media screen and (max-width:767px) {
  .form__table__heading {
    border-bottom: none;
    border-top: none;
    display: block;
    width: auto
  }
  .form__table__item {
    display: block;
    width: auto
  }
}

.form__table__item {
  border: 1px solid #aaa;
  padding: 15px
}

.form__table__required {
  background-color: #b99365;
  color: #fff;
  display: inline-block;
  float: right;
  font-size: 12px;
  padding: 3px 10px
}

.form__table__error {
  color: #b99365;
  display: block;
  font-size: 14px;
  padding-left: 5px;
  padding-top: 10px
}

.form__table__full {
  width: 100%
}

.form__btn,
.wpcf7-submit {
  background-color: #b99365;
  border-radius: 0;
  color: #fff;
  display: block;
  font-size: 20px;
  line-height: 70px;
  margin: 0 auto;
  width: 300px
}

@media screen and (max-width:767px) {
	.form__btn,
	.wpcf7-submit {
    font-size: 12px;
    line-height: 50px;
    text-align: center;
    width: 100%
  }
}

.form__btn:hover,
.wpcf7-submit:hover {
  transition: .4s;
  opacity: .8
}

.form__back {
  background-color: #F1F1F1;
  border: none;
  border-radius: 0;
  display: block;
  font-size: 16px;
  line-height: 50px;
  margin: 20px auto 0;
  width: 200px
}

@media screen and (max-width:767px) {
  .form__back {
    font-size: 10px;
    line-height: 40px;
    text-align: center;
    width: 80%
  }
}

.form__back:hover {
  transition: .4s;
  opacity: .8
}